define([
    'lodash',
    'santaProps/utils/propsSelectorsUtils',
    'santa-components'
], function (
    _,
    propsSelectorsUtils,
    santaComponents
) {
    'use strict';

    return {
        currentCurrency: propsSelectorsUtils.createComponentSantaTypeFetcher((state, props) => {
            const currentUrl = state.fetchSantaType(santaComponents.santaTypesDefinitions.currentUrl, state, props);
            return _.get(currentUrl.query, 'currency');
        })
    };
});

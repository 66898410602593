define(['lodash',
    'prop-types',
    'santa-components',
    'coreUtils',
    'santaProps/utils/propsSelectorsUtils',
    'santaProps/utils/contactForm/ContactFormSantaTypesConfig.json',
    'santaProps/utils/contactForm/oldModelHelper',
    'santaProps/utils/contactForm/newModelHelper'],
function (_,
          PropTypes,
          santaComponents,
          coreUtils,
          propsSelectorsUtils,
          contactFormConfig,
          oldModelHelper,
          newModelHelper) {
    'use strict';

    const getUserLang = state => state.fetchSantaType(santaComponents.santaTypesDefinitions.WixUserSantaTypes.userLanguage, state);

    function getContactFormTranslations(state) {
        const lang = getUserLang(state);
        return coreUtils.translationsLoader.getTranslationAllKeys('contactFormTranslations', lang);
    }

    function getSubscribeFormTranslations(state) {
        const lang = getUserLang(state);
        return coreUtils.translationsLoader.getTranslationAllKeys('subscribeFormTranslations', lang);
    }

    function preprocessCompData(compData, state) {
        const translations = getContactFormTranslations(state);
        const defaultData = _.merge(_.pick(translations, contactFormConfig.COMP_DATA_TRANSLATIONS_KEYS), {textDirection: 'left'});
        return _.defaults(_.clone(compData), defaultData);
    }

    function createFields(model, skin) {
        if (model.isNewModel) {
            return newModelHelper.createFieldsFromModel(model.compData);
        }
        return oldModelHelper.createFieldsFromModel(model.compData, model.compProp, skin);
    }

    function preprocessModel(state, props) {
        const compDataRaw = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        const isNew = newModelHelper.isNewModel(compDataRaw);
        const model = {
            isNewModel: isNew,
            compData: preprocessCompData(compDataRaw, state)
        };
        if (!model.isNewModel) {
            const compPropRaw = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compProp, state, props);
            model.compProp = oldModelHelper.preprocessCompProp(compPropRaw);
        }
        return model;
    }

    const createComponentSantaType = propsSelectorsUtils.createComponentSantaType;

    const contactFormTranslations = createComponentSantaType(PropTypes.object, function (state) {
        const contactForm = getContactFormTranslations(state);
        const subscribeForm = getSubscribeFormTranslations(state);
        return {contactForm, subscribeForm};
    });

    const orderedFields = createComponentSantaType(PropTypes.array, function (state, props) {
        const model = preprocessModel(state, props);
        const skin = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.skin, state, props);
        return createFields(model, skin);
    });

    const errorMessage = createComponentSantaType(PropTypes.string, function (state, props) {
        const model = preprocessModel(state, props);
        return _.get(model.compData, 'errorMessage');
    });

    const validationErrorMessage = createComponentSantaType(PropTypes.string, function (state, props) {
        const model = preprocessModel(state, props);
        return _.get(model.compData, 'validationErrorMessage');
    });

    const isDynamicContactForm = createComponentSantaType(PropTypes.bool, function (state, props) {
        const model = preprocessModel(state, props);
        return !!model.isNewModel;
    });

    const compMasterPageData = createComponentSantaType(PropTypes.bool, function (state, props) {
        const dataQuery = _.get(props.structure, 'dataQuery');
        return state.siteData.getMasterPageDataByQuery(dataQuery);
    });

    const siteApiForPromoteAnalytics = createComponentSantaType(PropTypes.object, function (state) {
        return {
            fireFacebookCustomEvent: state.siteAPI.fireFacebookCustomEvent,
            reportGoogleAnalytics: state.siteAPI.reportGoogleAnalytics,
            reportGoogleTagManager: state.siteAPI.reportGoogleTagManager,
            getSiteData: state.siteAPI.getSiteData,
            isExperimentOpen: state.siteAPI.isExperimentOpen
        };
    });

    return {
        contactFormTranslations,
        orderedFields,
        errorMessage,
        validationErrorMessage,
        compMasterPageData,
        isDynamicContactForm,
        siteApiForPromoteAnalytics
    };
});

define([
    'santa-components',
    'santaProps/utils/propsSelectorsUtils',
    'coreUtils'
], function (
    santaComponents,
    propsSelectorsUtils,
    coreUtils
) {
    'use strict';

    const {fileUploadManager} = coreUtils;
    const {createComponentSantaTypeFetcher} = propsSelectorsUtils;
    const {santaTypesDefinitions} = santaComponents;

    return () => {
        const uploadFile = createComponentSantaTypeFetcher((state, props) => ({file, fileType}) => {
            const authToken = state.fetchSantaType(santaTypesDefinitions.RendererModel.mediaAuthToken, state, props);

            return new Promise((resolve, reject) => {
                fileUploadManager.uploadFile({
                    file,
                    fileType,
                    authToken,
                    uploadCompleteCB: resolve,
                    uploadFailedCB: reject
                });
            });
        });

        return {
            uploadFile
        };
    };
});

define([
    'lodash',
    'santa-components',
    'santaProps/utils/propsSelectorsUtils',
    'santa-core-utils',
    'image-client-api'
], function (
    _,
    santaComponents,
    propsSelectorsUtils,
    coreUtilsLib,
    imageClientApi
) {
    'use strict';

    const {LEGACY_FULL} = imageClientApi.fittingTypes;
    const {createComponentSantaTypeFetcher} = propsSelectorsUtils;
    const {santaTypesDefinitions} = santaComponents;
    const {containerBackgroundUtils} = coreUtilsLib;

    return (siteAPI, siteData) => {
        const registerPlayer = createComponentSantaTypeFetcher(() => id => {
            const mediaAspect = siteAPI.getSiteAspect('mediaAspect');
            return mediaAspect.registerPlayer(id);
        });

        const unregisterPlayer = createComponentSantaTypeFetcher(() => id => {
            const mediaAspect = siteAPI.getSiteAspect('mediaAspect');
            return mediaAspect.unregisterPlayer(id);
        });

        const updatePlayerState = createComponentSantaTypeFetcher(() => (id, stateUpdate) => {
            const mediaAspect = siteAPI.getSiteAspect('mediaAspect');
            return mediaAspect.updatePlayerState(id, stateUpdate);
        });

        const shouldRenderSrc = createComponentSantaTypeFetcher(() => siteData.isInSSR());

        const imageUrlPreMeasureParams = createComponentSantaTypeFetcher((state, props) => {
            const structure = state.fetchSantaType(santaTypesDefinitions.Component.structure, state, props);
            if (!_.get(structure, ['layout', 'width']) || !_.get(structure, ['layout', 'height'])) {
                return {};
            }
            const compProp = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compProp, state, props);
            const displayMode = structure.componentType === 'wysiwyg.common.components.imagebutton.viewer.ImageButton' ? LEGACY_FULL : compProp.displayMode;
            const isMobileView = state.fetchSantaType(santaTypesDefinitions.isMobileView, state, props);
            const compData = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props) || {};
            const isInSeo = state.fetchSantaType(santaComponents.santaTypesDefinitions.isInSeo, state, props);

            return containerBackgroundUtils.getImageUrlPreMeasureParams(structure.layout, compData, displayMode, 'center', isMobileView, isInSeo);
        });

        return {
            shouldRenderSrc,
            imageUrlPreMeasureParams,
            registerPlayer,
            unregisterPlayer,
            updatePlayerState
        };
    };
});

define(['prop-types', 'santaProps/utils/propsSelectorsUtils'], function (PropTypes, propsSelectorsUtils) {
    'use strict';

    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;

    const windowTouchEvents = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteAPI.getSiteAspect('windowTouchEvents');
    }, 'windowTouchEvents');

    const windowKeyboardEvent = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteAPI.getSiteAspect('windowKeyboardEvent');
    }, 'windowKeyboardEvent');

    const windowClickEventAspect = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteAPI.getSiteAspect('windowClickEventAspect');
    }, 'windowClickEventAspect');

    const viewportChangeAspect = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteAPI.getSiteAspect('viewportChange');
    }, 'viewportChangeAspect');

    const siteScrollingBlocker = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteAPI.getSiteAspect('siteScrollingBlocker');
    }, 'siteScrollingBlocker');

    const siteMembers = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteAPI.getSiteAspect('siteMembers');
    }, 'siteMembers');

    const windowFocusEvents = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteAPI.getSiteAspect('windowFocusEvents');
    }, 'windowFocusEvents');

    const anchorChangeEvent = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteAPI.getSiteAspect('anchorChangeEvent');
    }, 'anchorChangeEvent');

    const externalScriptLoader = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteAPI.getSiteAspect('externalScriptLoader');
    }, 'externalScriptLoader');

    const svSessionChangeEvent = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteAPI.getSiteAspect('svSessionChangeEvent');
    }, 'svSessionChangeEvent');

    const consentPolicyUpdateEvent = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteAPI.getSiteAspect('consentPolicyUpdateEvent');
    }, 'consentPolicyUpdateEvent');

    const commonConfigUpdateEvent = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteAPI.getSiteAspect('commonConfigUpdateEvent');
    }, 'commonConfigUpdateEvent');

    const siteMetadataChangeAspect = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteAPI.getSiteAspect('siteMetadataChangeAspect');
    }, 'siteMetadataChangeAspect');

    const mediaAspect = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteAPI.getSiteAspect('mediaAspect');
    }, 'mediaAspect');

    const designDataChangeAspect = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteAPI.getSiteAspect('designDataChangeAspect');
    }, 'designDataChangeAspect');

    const tpaComponentsDomAspect = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteAPI.getSiteAspect('tpaComponentsDomAspect');
    }, 'tpaComponentsDomAspect');

    const tpaPageNavigationAspect = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteAPI.getSiteAspect('tpaPageNavigationAspect');
    }, 'tpaPageNavigationAspect');

    const dynamicClientSpecMapAspect = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteAPI.getSiteAspect('dynamicClientSpecMap');
    }, 'dynamicClientSpecMapAspect');

    return {
        siteMembers,
        siteScrollingBlocker,
        svSessionChangeEvent,
        consentPolicyUpdateEvent,
        commonConfigUpdateEvent,
        siteMetadataChangeAspect,
        windowFocusEvents,
        windowKeyboardEvent,
        windowTouchEvents,
        windowClickEventAspect,
        viewportChangeAspect,
        anchorChangeEvent,
        externalScriptLoader,
        mediaAspect,
        designDataChangeAspect,
        tpaComponentsDomAspect,
        tpaPageNavigationAspect,
        dynamicClientSpecMapAspect
    };
});

define(['lodash', 'coreUtils', 'santaProps/utils/propsSelectorsUtils'], function (_, coreUtils, propsSelectorsUtils) {
    'use strict';

    const createGlobalSantaTypesFetcher = propsSelectorsUtils.createGlobalSantaTypesFetcher;
    const siteDataUtils = coreUtils.siteDataUtils;


    return (siteAPI, siteData) => {
        const booleanBrowserFlags = _.transform([
            'fixedBackgroundColorBalata',
            'fixedSiteBackground',
            'cssFiltersSupported',
            'useDropShadowFilterOnVideo',
            'cssFeatureQueriesSupported'
        ], (result, flagName) => {
            result[flagName] = createGlobalSantaTypesFetcher(() => siteDataUtils.getBrowserFlag(siteData, flagName), flagName);
        }, {});

        const functionBrowserFlags = _.transform([
            'ios',
            'forceOverflowScroll',
            'animateTinyMenuIcon',
            'highlightAnchorsInMenu',
            'positionFixedShouldBeAbsoluteAtPageBottom',
            'mixBlendModeSupported',
            'webglCrossOriginSupported',
            'webglVideoTextureSupported',
            'clipParallaxWithWebkitClipPath'
        ], (result, flagName) => {
            result[flagName] = createGlobalSantaTypesFetcher(() => siteDataUtils.getBrowserFlag.bind(null, siteData, flagName), flagName);
        }, {});

        return _.assign({}, functionBrowserFlags, booleanBrowserFlags);
    };
});

define(['lodash', 'utils', 'santaProps/utils/propsSelectorsUtils', 'santa-components', 'warmupUtils', 'coreUtils'], function (_, utils, propsSelectorsUtils, santaComponents, warmupUtils, coreUtils) {
    'use strict';

    const {tpaWarmup} = warmupUtils;

    const createComponentSantaTypeFetcher = propsSelectorsUtils.createComponentSantaTypeFetcher;
    const PRIME_COLORS_REFERENCES = ['white/black', 'black/white', 'primery-1', 'primery-2', 'primery-3'];
    const NON_PRIME_COLORS_PREFIX = 'color-';

    const getColorReferenceByColorName = function (colorName) {
        const editorIndex = Number(colorName.split('_').pop());
        if (editorIndex <= 5) {
            return PRIME_COLORS_REFERENCES[editorIndex - 1];
        } else if (editorIndex > 10) {
            return NON_PRIME_COLORS_PREFIX + (editorIndex - 10);
        }
    };

    const addColorRef = function (siteColors) {
        return siteColors.map(color => _.assign(color, {
            reference: getColorReferenceByColorName(color.name)
        }));
    };

    return (siteAPI, getStyleIdFromStructure) => {
        const siteData = siteAPI.getSiteData();

        const getComponent = createComponentSantaTypeFetcher((state, props) => {
            const compData = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
            return siteData.nativeComponentRegistrar.getComponent(compData.widgetId);
        });

        const widgetStyle = createComponentSantaTypeFetcher((state, props) => {
            const styleId = getStyleIdFromStructure(props);
            const documentType = siteData.rendererModel.siteInfo.documentType;
            const characterSets = siteData.getDataByQuery('masterPage').characterSets;

            const themeData = utils.styleUtils.getAllThemeForSDK(siteData, props.rootId, styleId);
            const styleParams = utils.styleUtils.getStylesForSDK(themeData, styleId, false, siteData.serviceTopology, documentType, characterSets, true);
            const siteColors = utils.styleUtils.getSiteColors(themeData.THEME_DATA.color);
            const siteTextPresets = utils.styleUtils.getTextPresets(themeData.THEME_DATA.font);
            const siteColorsWithRef = addColorRef(siteColors);
            return {
                styleParams,
                siteColors: siteColorsWithRef,
                siteTextPresets
            };
        });

        const resizeComponent = createComponentSantaTypeFetcher(() => _.noop);

        const appLoadBI = createComponentSantaTypeFetcher((state, props) => {
            const compId = props.id;
            const compData = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
            if (!compData) {
                return;
            }
            const {widgetId, applicationId} = compData;
            const {appDefinitionId} = siteData.getClientSpecMapEntry(applicationId);
            const params = {widgetId, appDefinitionId};
            const loaded = () => {
                tpaWarmup.reportWarmupPerformanceBiEvent(tpaWarmup.events.APP_LOADED_SUCCESSFULLY, siteData, compId, params);
            };
            return {
                loaded
            };
        });

        const viewMode = createComponentSantaTypeFetcher((state, props) => {
            const isViewerMode = siteData.isViewerMode();
            const componentViewMode = state.fetchSantaType(santaComponents.santaTypesDefinitions.RenderFlags.componentViewMode, state, props);
            return _.capitalize(isViewerMode ? 'site' : componentViewMode);
        });

        const formFactor = createComponentSantaTypeFetcher(() => _.capitalize(siteData.getDeviceType()));

        const registerToSiteReady = createComponentSantaTypeFetcher(() => siteAPI.registerToSiteReady.bind(siteAPI));

        const handleEvent = createComponentSantaTypeFetcher((state, props) => ({callbackId, eventArgs, contextId}) => {
            if (siteData.isInSSR()) {
                return; //do nothing in SSR since the worker will not wait for the postMessage in SSR
            }
            const widgetHandler = siteAPI.getSiteAspect('WidgetAspect').getWidgetHandler();
            widgetHandler.handleNativeTpaEvent(contextId, {callbackId, eventArgs, compId: props.id});
        });

        const compProps = createComponentSantaTypeFetcher((state, props) => siteAPI.getSiteAspect('tpaWidgetNativeAspect').getControllerProps(props.id || _.get(props, 'structure.id')));

        const deadComponentTranslations = createComponentSantaTypeFetcher(state => {
            const feature = 'Platform';
            const languageCode = state.siteData.getLanguageCode();
            return {
                text: coreUtils.translationsLoader.getTranslation(feature, languageCode, 'PLATFORM_messages_JS_viewer_error_text'),
                title: coreUtils.translationsLoader.getTranslation(feature, languageCode, 'PLATFORM_messages_JS_viewer_error_title')
            };
        });

        const boltComponents = createComponentSantaTypeFetcher(() => {});

        const LazySentry = createComponentSantaTypeFetcher(() => class {
            captureException() {
                return;
            }
            configureScope() {
                return;
            }
        });

        return {
            widgetStyle,
            appLoadBI,
            resizeComponent,
            formFactor,
            viewMode,
            getComponent,
            registerToSiteReady,
            handleEvent,
            compProps,
            deadComponentTranslations,
            boltComponents,
            LazySentry
        };
    };
});

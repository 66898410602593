define([
    'lodash',
    'coreUtils',
    'image-client-api',
    'utils',
    'color',
    'santa-components',
    'santaProps/utils/propsSelectorsUtils',
    'santaProps/types/modules/MemberLoginSantaTypes'
], function (_,
             coreUtils,
             imageClientLib,
             utils,
             Color,
             santaComponents,
             propsSelectorsUtils,
             MemberLoginSantaTypes) {
    'use strict';

    /**
     * @typedef {SVG_TYPES} SVG_TYPES
     * @type {{SHAPE: string, TINT: string, COLOR: string, UGC: string}}
     */
    const {
        SVG_TYPES,
        COLOR_DEFAULT,
        SHAPE_STYLE_DEFAULTS,
        ART_STYLE_DEFAULTS,
        SKIN_STYLE_KEYS_MAP
    } = coreUtils.svgUtils;

    const siteDataUtils = coreUtils.siteDataUtils;
    const themeColorMatcher = /^color_([0-9]{1,2}|100)$/;
    const svgTagMatcher = /(<svg[^>]*)(>)/;
    const fillAttributeMatcher = /fill="(.*?)"/gi;
    const viewBoxMatcher = /viewBox="[^"]+"/i;
    const preserveAspectRatioMatcher = /preserveAspectRatio="[^"]*"/i;
    const styleMatcher = /style="([^"]*)"/i;
    const styleTransformMatcher = /transform:([^;]*)/i;
    const svgWidthMatcher = /(?:<svg[^>]*)\s(width="[^"]*")/i;
    const svgHeightMatcher = /(?:<svg[^>]*)\s(height="[^"]*")/i;
    const gTagMatcher = /(<g[^>]*)>/;
    const filterAttrMatcher = /filter="url\(#([^"]+)\)"/;

    const getViewBoxString = ({x, y, width, height}) => `${x} ${y} ${width} ${height}`;

    const getViewBoxObject = viewBox => {
        const vieBoxArr = viewBox.split(' ');
        return {
            x: vieBoxArr[0],
            y: vieBoxArr[1],
            width: vieBoxArr[2],
            height: vieBoxArr[3]
        };
    };

    const getSiteColors = state => state.fetchSantaType(santaComponents.santaTypesDefinitions.Theme.colorsMap, state);

    /**
     *
     * @param {{x: number, y: number, width: number, height: number}} boxBoundaries
     * @param {number} strokeWidth
     * @param {{width: number, height: number}} size, the target dom size
     * @param {boolean} maintainAspectRatio
     */
    function getScaledViewBox(boxBoundaries, strokeWidth, size, maintainAspectRatio) {
        const wScale = (size.width - strokeWidth) / boxBoundaries.width;
        const hScale = (size.height - strokeWidth) / boxBoundaries.height;
        const aspectScale = Math.min(wScale, hScale);

        const width = size.width / (maintainAspectRatio ? aspectScale : wScale);
        const height = size.height / (maintainAspectRatio ? aspectScale : hScale);
        const x = boxBoundaries.x - (width - boxBoundaries.width) / 2; // eslint-disable-line no-mixed-operators
        const y = boxBoundaries.y - (height - boxBoundaries.height) / 2; // eslint-disable-line no-mixed-operators

        return {
            width,
            height,
            x,
            y
        };
    }

    /**
     *
     * @param props
     * @returns {{width: (*|number), height: (*|number)}}
     */
    function getLayout(props) {
        const layout = _.get(props, ['structure', 'layout'], {});
        return {width: layout.width || 0,
            height: layout.height || 0
        };
    }

    /**
     *
     * @param shapeStyle
     * @param layout
     * @param bbox
     * @param preserveAspectRatio
     * @param svgString
     * @returns {string}
     */
    function getScaledSvg(shapeStyle, layout, bbox, preserveAspectRatio, svgString) {
        const strokeWidth = shapeStyle && transformStrokeWidth(shapeStyle).strokeWidth;
        if (!strokeWidth) {
            return svgString.replace(viewBoxMatcher, `viewBox="${bbox}"`);
        }
        const scaledViewBox = getScaledViewBox(getViewBoxObject(bbox), strokeWidth, layout, preserveAspectRatio);
        return svgString.replace(viewBoxMatcher, `viewBox="${getViewBoxString(scaledViewBox)}"`);
    }

    /**
     * receive a style object and normalize strokeWidth value by enableStroke value
     * @param style
     */
    function transformStrokeWidth(style) {
        const enableStroke = style.enableStroke === 'false' ? false : !!style.enableStroke;
        const strokeWidth = enableStroke ? parseInt(_.get(style, 'strokeWidth', 1), 10) : 0;
        return _(style)
            .omit('enableStroke')
            .set('strokeWidth', strokeWidth)
            .value();
    }

    /**
     * Add accessibility role and label from alt text
     * @param {string} svgString
     * @param {string} altText
     * @param {string} id
     * @returns {string} SVG string
     */
    function addA11yFeatures(svgString, altText, id) {
        const svgLabelId = `${id}-svgtitle`;
        let newSvgString = svgString.replace(svgTagMatcher, '$1 role="img"$2');
        if (_.isString(altText)) {
            newSvgString = newSvgString.replace(svgTagMatcher, `$1 aria-labelledby="${svgLabelId}"$2<title id="${svgLabelId}">${altText}</title>`);
        }
        return newSvgString;
    }

    /**
     * Tinted SVG
     * search for all 'fill' attributes and replace with tint color'.
     * @param {object} fillColor
     * @param {object} colorsMap
     * @param {string} svgString
     * @return {string} SVG string
     */
    function transformToTintColors(fillColor, colorsMap, svgString) {
        const resolvedColor = utils.colorParser.getColor(colorsMap, fillColor);
        const baseColor = new Color(resolvedColor);
        let tintedColor;

        const newSvgString = svgString.replace(fillAttributeMatcher, function (full, colorToTint) {
            const colorObj = new Color(colorToTint);

            if (isGreyscale(colorObj)) {
                const tint = 1 - (255 - colorObj.red()) / 255; // eslint-disable-line no-mixed-operators
                const rTint = Math.floor(baseColor.red() + (255 - baseColor.red()) * tint); // eslint-disable-line no-mixed-operators
                const gTint = Math.floor(baseColor.green() + (255 - baseColor.green()) * tint); // eslint-disable-line no-mixed-operators
                const bTint = Math.floor(baseColor.blue() + (255 - baseColor.blue()) * tint); // eslint-disable-line no-mixed-operators
                tintedColor = new Color({red: rTint, green: gTint, blue: bTint});
                // return tinted color
                return `fill="${tintedColor.hexString()}"`;
            }

            // no change, return original svg color
            return `fill="${colorToTint}"`;
        });

        return newSvgString;
    }

    /**
     * Check if color is greyscale
     * @param colorObj
     * @returns {boolean}
     */
    function isGreyscale(colorObj) {
        return colorObj.red() === colorObj.green() &&
            colorObj.red() === colorObj.blue() &&
            colorObj.red() !== 255;
    }

    function setViewBox(svgString, svgInfo, shouldReplace) {
        if (!svgString.match(viewBoxMatcher)) {
            const {svgType, viewBox, bbox} = svgInfo;
            // if it's UGC type we set viewBox to the one saved in info
            // since it does not exist on the SVG element and we default to "0 0 <width> <height>"
            return svgType === SVG_TYPES.UGC ?
                svgString.replace(/<svg/, `<svg viewBox="${viewBox}"`) :
                svgString.replace(/<svg/, `<svg viewBox="${bbox}"`);
        } else if (shouldReplace && svgInfo.svgType !== SVG_TYPES.UGC && svgInfo.bbox) {
            return svgString.replace(viewBoxMatcher, `viewBox="${svgInfo.bbox}"`);
        }

        return svgString;
    }

    function setPreserveAspectRatio(svgString, value) {
        const topSvg = svgString.match(svgTagMatcher);

        if (topSvg) {
            if (preserveAspectRatioMatcher.test(topSvg[0])) {
                return svgString.replace(preserveAspectRatioMatcher, `preserveAspectRatio="${value}"`);
            }

            return svgString.replace(/<svg/, `<svg preserveAspectRatio="${value}"`);
        }

        return svgString;
    }

    // eslint-disable-next-line complexity
    function getFlippedSVG(svgString, flip) {
        const topSvg = svgString.match(svgTagMatcher);
        let transform;

        switch (flip) {
            case 'x':
                transform = 'scale(-1, 1)';
                break;
            case 'y':
                transform = 'scale(1, -1)';
                break;
            case 'xy':
                transform = 'scale(-1, -1)';
                break;
        }

        if (topSvg && transform) {
            const styleMatch = topSvg[0].match(styleMatcher);
            let replacement = `$1 style="transform: ${transform};"$2`;

            if (styleMatch) {
                const transformMatch = styleMatch[1].match(styleTransformMatcher);

                if (transformMatch) {
                    replacement = topSvg[0].replace(
                        styleMatch[0],
                        styleMatch[0].replace(transformMatch[0], `transform: ${transform} ${transformMatch[1]}`)
                    );
                } else {
                    replacement = topSvg[0].replace(
                        styleMatch[0],
                        `style="transform: ${transform}; ${styleMatch[1]}"`
                    );
                }
            }

            return svgString.replace(svgTagMatcher, replacement);
        }

        return svgString;
    }

    /**
     *
     * @param state
     * @param props
     * @param svgId
     * @param [properties]
     * @param [data]
     * @param [design]
     * @param [style]
     * @returns {string}
     */
    // eslint-disable-next-line complexity
    function getSvgStringByIdAndData(state, props, svgId, properties, data, design, style) {
        const {overrideColors, shapeStyle, shadow} = design || {};

        const id = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.id, state, props);
        const colorsMap = getSiteColors(state);
        let svgString = siteDataUtils.getSvgString(state.siteData, svgId) || '';
        const {svgType, viewBox, bbox} = getSvgInfoById(state, props, svgId);

        const {preserveViewBox, displayMode} = properties || {};
        const preserveAspectRatio = displayMode !== 'stretch';
        const preserveAspectRatioString = preserveAspectRatio ? 'xMidYMid meet' : 'none';
        const layout = getLayout(props);

        svgString = setPreserveAspectRatio(svgString, preserveAspectRatioString);

        svgString = addA11yFeatures(svgString, _.get(data, 'alt'), id);

        if (svgType === SVG_TYPES.TINT) {
            svgString = transformToTintColors(_.get(overrideColors, 'color1', COLOR_DEFAULT.color1), colorsMap, svgString);
        }

        svgString = setViewBox(svgString, {svgType, viewBox, bbox});

        if (svgType !== SVG_TYPES.UGC && !preserveViewBox && bbox) {
            svgString = getScaledSvg(shapeStyle, layout, bbox, preserveAspectRatio, svgString);
        }

        if (!_.isEmpty(shadow)) {
            svgString = wrapWithShadow(id, svgString, shadow, style || {}, colorsMap);
        }

        return svgString;
    }

    function getSvgStringForMask(state, props, svgId, flip, type, shadow, layout) {
        let svgString = siteDataUtils.getSvgString(state.siteData, svgId) || '';
        const svgInfo = getSvgInfoById(state, props, svgId);

        svgString = setPreserveAspectRatio(svgString, 'none');

        svgString = getFlippedSVG(svgString, flip);

        if (type === 'luminance') {
            svgString = transformToLuminanceMask(svgString, props.id);
        }

        const content = setViewBox(svgString, svgInfo, true);

        if (shadow) {
            shadow.mergeGraphic = false;
        }

        return type === 'drop-shadow' ? wrapWithShadow(props.id, content, shadow, layout, getSiteColors(state), true) : content;
    }

    /**
     * Adds a filter to entire content of given SVG content
     *
     * @param {string} svg SVG content
     * @param {string} filterId identifier to use for referencing the filter
     * @param {string} filterPart SVG string containing the filter
     * @return {string} transformed SVG content
     */
    function applyFilterToContent(svg, filterId, filterPart) {
        const topSvg = svg.match(svgTagMatcher);
        const defs = `<defs>${filterPart}</defs>`;

        if (topSvg) {
            const filterAttr = topSvg[0].match(filterAttrMatcher);

            if (filterAttr) {
                const topG = svg.match(gTagMatcher);

                if (topG) {
                    return svg.replace(topSvg[0], `${topSvg[0].replace(filterAttr[0], `filter="url(#${filterId})"`)}${defs}`)
                        .replace(topG[0], topG[0].replace(/<g/, `<g filter="url(#${filterAttr[1]})"`));
                }

                return svg.replace(
                    topSvg[0],
                    `${topSvg[0].replace(
                        filterAttr[0],
                        `filter="url(#${filterId})"`
                    )}${defs}<g filter="url(#${filterAttr[1]})">`
                ).replace(/<\/svg>/, '</g></svg>'); // TODO: assuming only a single </svg> in the content
            }

            return svg.replace(topSvg[0], `${topSvg[0].replace(/<svg/, `<svg filter="url(#${filterId})"`)}${defs}`);
        }

        return svg;
    }

    /**
     * Transforms the luminance values of the SVG content's graphics into alpha values
     *
     * @param {string} svg SVG content
     * @param {string} id identifier to ensure filter id is unique
     * @return {string} transformed SVG content
     */
    function transformToLuminanceMask(svg, id) {
        const filterId = `luminance-${id}`;
        const filterPart = `<filter id="${filterId}">
    <feColorMatrix type="luminanceToAlpha" result="luma"/>
    <feComposite in="luma" in2="SourceAlpha" operator="in"/>
</filter>`;

        return applyFilterToContent(svg, filterId, filterPart);
    }

    function getFilterRectInPixels(style, shadow) {
        const {width, height} = style;
        const {blurRadius, x, y} = shadow;
        // STD to pixel ~ STD value * 3 , we are multiplying by 6 for both sides
        const blurSpread = blurRadius * 6;
        return {
            x: Math.min(0, x) - (blurSpread / 2),
            y: Math.min(0, y) - (blurSpread / 2),
            width: width + blurSpread + Math.abs(x),
            height: height + blurSpread + Math.abs(y),
            filterUnits: 'userSpaceOnUse'
        };
    }

    function getShadowFilter(filterId, shadow, style, colorsMap) {
        const filterAttrs = getFilterRectInPixels(style, shadow);
        const color = utils.colorParser.getHexColor(colorsMap, shadow.color);
        return coreUtils.svgFilters.getShadow(filterId, _.assign({}, shadow, {color}), filterAttrs);
    }

    /**
     * Change width/height to 100%
     *
     * @param {string} svgString SVG content
     * @param {boolean=false} [force] whether to add width/height=100% when absent
     * @return {*}
     */
    // eslint-disable-next-line complexity
    function modifyWidthAndHeight(svgString, force) {
        if (svgString) {
            const widthMatch = svgString.match(svgWidthMatcher);
            const heightMatch = svgString.match(svgHeightMatcher);
            if (widthMatch && widthMatch.length > 1) {
                svgString = svgString.replace(widthMatch[1], 'width="100%"');
            } else if (force) {
                svgString = svgString.replace(/<svg/, '<svg width="100%"');
            }
            if (heightMatch && heightMatch.length > 1) {
                svgString = svgString.replace(heightMatch[1], 'height="100%"');
            } else if (force) {
                svgString = svgString.replace(/<svg/, '<svg height="100%"');
            }
        }
        return svgString;
    }

    function wrapWithShadow(id, content, shadow, style, colorsMap) {
        const filterId = `${id}-shadow`;
        const shadowFilter = getShadowFilter(filterId, shadow, style, colorsMap);
        const modifiedContent = modifyWidthAndHeight(content);
        //<rect ${getFilterRectInPixels(style, shadow)} fill="transparent" stroke-width="1" stroke="red" stroke-opacity="0.5"></rect>
        return `
            <svg height="100%" width="100%">
                <defs>${shadowFilter}</defs>
                <g filter="url(#${filterId})">
                    ${modifiedContent}
                </g>
            </svg>
        `;
    }

    /**
     *
     * @param state
     * @param props
     * @param svgId
     * @returns {string}
     */
    function getSvgTypeById(state, props, svgId) {
        const svgInfo = getSvgInfoById(state, props, svgId);
        return _.get(svgInfo, ['svgType'], SVG_TYPES.SHAPE);
    }

    /**
     *
     * @param state
     * @param props
     * @param svgId
     * @returns {string}
     */
    function getSvgInfoById(state, props, svgId) {
        return siteDataUtils.getSvgInfo(state.siteData, svgId) || {};
    }

    /**
     * Only for type color
     * In the component we will need to add `${styleId }_${ id}` to each selector;
     * @param state
     * @param props
     * @param design
     * @param svgType
     * @returns {array}
     */
    function getOverrideColorsAsCssByData(state, props, design, svgType) {
        let styles = [];
        const overrideColors = _.get(design, 'overrideColors');
        if (svgType === SVG_TYPES.COLOR && !_.isEmpty(overrideColors)) {
            const colorsMap = getSiteColors(state);
            const colorTemplate = _.template('svg [data-color="${colorIndex}"] {fill: ${colorValue};}\n');

            styles = _.map(overrideColors, (color, colorName) => {
                const resolvedColor = utils.colorParser.getColor(colorsMap, color);
                const index = colorName.replace('color', '');
                return colorTemplate({colorIndex: index, colorValue: resolvedColor});
            });
        }
        return styles;
    }

    /**
     *
     * @param state
     * @param props
     * @param design
     * @param styleProps
     * @param svgType
     * @returns {string}
     */
    function getShapeStyleByDataAndStyle(state, props, design, styleProps, svgType) {
        const shapeStyle = _.clone(_.get(design, 'shapeStyle', {}));
        // Support old styles in skin
        const skinStyle = _.mapKeys(styleProps, function (value, key) {
            return SKIN_STYLE_KEYS_MAP[key];
        });

        let style;

        if (svgType === SVG_TYPES.SHAPE) {
            const colorsMap = getSiteColors(state);
            const opacityMap = ['opacity', 'fillOpacity', 'strokeOpacity'];
            shapeStyle.fill = _.get(design, ['overrideColors', 'color1'], COLOR_DEFAULT.color1);

            style = _(skinStyle)
                .defaults(shapeStyle, SHAPE_STYLE_DEFAULTS)
                .thru(transformStrokeWidth)
                .mapValues(function (value, key) {
                    let returnedValue = value;
                    if (_.includes(opacityMap, key)) {
                        returnedValue = parseFloat(value);
                    } else if (themeColorMatcher.test(value)) {
                        returnedValue = utils.colorParser.getColor(colorsMap, value);
                    }
                    return returnedValue;
                })
                .mapKeys(function (value, key) {
                    return key === 'opacity' ? 'fillOpacity' : key;
                })
                .value();
        } else if (svgType === SVG_TYPES.TINT) {
            const colorsMap = getSiteColors(state);
            const fill = skinStyle.fill || _.get(design, ['overrideColors', 'color1'], COLOR_DEFAULT.color1);

            style = {
                opacity: _.get(skinStyle, 'opacity', _.get(shapeStyle, 'opacity', ART_STYLE_DEFAULTS.opacity)),
                // set a default for shapes with no fill attribute
                fill: themeColorMatcher.test(fill) ? utils.colorParser.getColor(colorsMap, fill) : fill
            };
        } else {
            style = {opacity: _.get(skinStyle, 'opacity', _.get(shapeStyle, 'opacity', ART_STYLE_DEFAULTS.opacity))};
        }
        return style;
    }

    /**
     * TODO: When there is no styling info in data, could be removed
     * @param state
     * @param props
     * @returns {*}
     */
    function getStyleAndColors(state, props) {
        const data = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        const design = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compDesign, state, props);
        return _.isEmpty(design) ? data : design;
    }

    /**
     * Get component link properties
     * @returns {{}|null}
     */
    const getSvgLink = propsSelectorsUtils.createComponentSantaTypeFetcher((state, props) => {
        const data = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        const linkRenderInfo = state.fetchSantaType(santaComponents.santaTypesDefinitions.Link.renderInfo, state, props);
        const rootNavigationInfo = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.rootNavigationInfo, state, props);
        const link = _.get(data, 'link');

        if (link) {
            return coreUtils.linkRenderer.renderLink(link, linkRenderInfo, rootNavigationInfo);
        }

        return null;
    });

    /**
     * Legacy prop type for SvgShape component
     * Calculate the stroke width of the svg from style params
     * @returns {number}
     */
    const getStrokeWidth = propsSelectorsUtils.createComponentSantaTypeFetcher((state, props) => {
        const style = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.theme, state, props);

        const strokeWidth = _.get(style, ['style', 'properties', 'strokewidth'], 0);
        return parseInt(strokeWidth, 10);
    });

    /**
     * Get SVG Id for comp, look at data.svgId
     * @returns {string}
     */
    const getSvgId = propsSelectorsUtils.createComponentSantaTypeFetcher((state, props) => {
        const data = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        return _.get(data, ['svgId'], '');
    });

    /**
     * Get SVG String from store based on svgId from data
     * Add accessibility notations and if the type of the svg is tinted svg, color it
     * @returns {string}
     */
    const getSvgString = propsSelectorsUtils.createComponentSantaTypeFetcher((state, props) => {
        const data = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        const style = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.style, state, props);
        const properties = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compProp, state, props);
        const design = getStyleAndColors(state, props);
        const svgId = state.fetchSantaType(santaComponents.santaTypesDefinitions.VectorImage.svgId, state, props);
        return svgId ? getSvgStringByIdAndData(state, props, svgId, properties, data, design, style) : '';
    });

    /**
     * Get SVG String from store based on svgId from data
     * Add accessibility notations and if the type of the svg is tinted svg, color it
     * @returns {string}
     */
    const getSvgStringFromCropData = propsSelectorsUtils.createComponentSantaTypeFetcher((state, props) => {
        const data = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        const properties = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compProp, state, props);
        const svgId = _.get(properties, 'overrideCrop.svgId', _.get(data, 'crop.svgId'));
        return svgId ? getSvgStringByIdAndData(state, props, svgId, properties) : '';
    });

    /**
     * Get SVG String from store and use legacy skin name as svgId
     * Add accessibility notations and if the type of the svg is tinted svg, color it
     * @returns {string}
     */
    const getLegacySvgString = propsSelectorsUtils.createComponentSantaTypeFetcher((state, props) => {
        const svgId = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.skin, state, props);
        const styleProps = _.get(state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.theme, state, props), ['style', 'properties']);
        _.assign(styleProps, styleProps && styleProps.strokewidth ? {
            strokeWidth: styleProps.strokewidth,
            enableStroke: true
        } : {});
        const data = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        const properties = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compProp, state, props);
        return svgId ? getSvgStringByIdAndData(state, props, svgId, properties, data, {shapeStyle: styleProps}) : '';
    });

    /**
     * Get the svg type from svg info
     * @returns {string}
     */
    const getSvgType = propsSelectorsUtils.createComponentSantaTypeFetcher((state, props) => {
        const svgId = state.fetchSantaType(santaComponents.santaTypesDefinitions.VectorImage.svgId, state, props);
        return svgId ? getSvgTypeById(state, props, svgId) : '';
    });

    /**
     * Get the svg type from svg info
     * @returns {string}
     */
    const getSvgInfo = propsSelectorsUtils.createComponentSantaTypeFetcher((state, props) => {
        const svgId = state.fetchSantaType(santaComponents.santaTypesDefinitions.VectorImage.svgId, state, props);
        return svgId ? getSvgInfoById(state, props, svgId) : {};
    });

    /**
     * Get the svg type from svg info
     * @returns {string}
     */
    const getLegacySvgInfo = propsSelectorsUtils.createComponentSantaTypeFetcher((state, props) => {
        const svgId = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.skin, state, props);
        return svgId ? getSvgInfoById(state, props, svgId) : {};
    });

    /**
     * Generate a <style> element contents from vector art overrideColors in data
     * @returns {string}
     */
    const getOverrideColorsAsCss = propsSelectorsUtils.createComponentSantaTypeFetcher((state, props) => {
        const design = getStyleAndColors(state, props);
        const svgType = state.fetchSantaType(santaComponents.santaTypesDefinitions.VectorImage.svgType, state, props);
        return _.get(design, 'overrideColors') ? getOverrideColorsAsCssByData(state, props, design, svgType) : [];
    });

    /**
     * Generate a react style object from either style or data of the svg
     * @returns {object}
     */
    const getShapeStyle = propsSelectorsUtils.createComponentSantaTypeFetcher((state, props) => {
        // For compatibility with shapes that has style
        const styleProps = _.get(state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.theme, state, props), ['style', 'properties']);
        const design = getStyleAndColors(state, props);
        const svgId = state.fetchSantaType(santaComponents.santaTypesDefinitions.VectorImage.svgId, state, props);
        const svgType = state.fetchSantaType(santaComponents.santaTypesDefinitions.VectorImage.svgType, state, props);
        return svgId ? getShapeStyleByDataAndStyle(state, props, design, styleProps, svgType) : {};
    });

    /**
     * Generate a map of svgIds and their required svg props for rendering loginSocialBar and tinyMenuMemberSection
     * (those who require memberLoginSantaTypes)
     * @returns {object}
     */
    const svgPropsForMemberLoginIconItems = propsSelectorsUtils.createComponentSantaTypeFetcher((state, props) => {
        const result = {};
        const defaultAvatar = MemberLoginSantaTypes.memberDefaultAvatar.fetch(state, props);
        if (defaultAvatar) {
            result[defaultAvatar] = {
                svgId: defaultAvatar,
                preserveViewBox: true
            };
        }

        const iconItems = MemberLoginSantaTypes.iconItems.fetch(state, props);
        if (iconItems && _.isArray(iconItems)) {
            _.forEach(iconItems, function (item) {
                const svgId = _.get(item, 'iconRef.svgId');
                if (svgId) {
                    result[svgId] = {
                        svgId,
                        preserveViewBox: true
                    };
                }
            });
        }

        const styleProps = _.get(state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.theme, state, props), ['style', 'properties']);
        _.forEach(result, function (prop, svgId) {
            const svgType = getSvgTypeById(state, props, svgId);
            const svgInfo = getSvgInfoById(state, props, svgId);
            result[svgId] = _.assign(result[svgId], {
                svgString: getSvgStringByIdAndData(state, props, svgId, {preserveViewBox: true, displayMode: 'fit'}),
                svgType,
                svgInfo,
                overrideColorsAsCss: getOverrideColorsAsCssByData(state, props, null, svgType),
                shapeStyle: getShapeStyleByDataAndStyle(state, props, null, styleProps)
            });
        });

        return !_.isEmpty(result) ? result : null;
    });

    const svgPropsMapForMediaControls = propsSelectorsUtils.createComponentSantaTypeFetcher((state, props) => {
        const design = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compDesign, state, props) || {};
        const svgProps = _.reduce(design.icons, (res, item) => {
            const itemDesign = _.merge({}, design.iconsDefaultDesign, item.iconDesign);
            const svgId = item.svgId;
            const svgType = getSvgTypeById(state, props, svgId);
            const svgInfo = getSvgInfoById(state, props, svgId);
            const style = {};
            res[item.name] = {
                style,
                svgType,
                svgInfo,
                svgId,
                svgString: getSvgStringByIdAndData(state, props, svgId, {preserveViewBox: true, displayMode: 'fit'}, null, itemDesign),
                overrideColorsAsCss: getOverrideColorsAsCssByData(state, props, itemDesign, svgType),
                shapeStyle: getShapeStyleByDataAndStyle(state, props, itemDesign, null, svgType),
                compProp: {displayMode: imageClientLib.fittingTypes.SCALE_TO_FIT}
            };
            return res;
        }, {});

        return svgProps;
    });

    const getPreserveViewBox = propsSelectorsUtils.createComponentSantaTypeFetcher((state, props) => {
        const svgType = state.fetchSantaType(santaComponents.santaTypesDefinitions.VectorImage.svgType, state, props);
        return svgType === SVG_TYPES.UGC;
    });

    const getShouldScaleStroke = propsSelectorsUtils.createComponentSantaTypeFetcher((state, props) => {
        const svgType = state.fetchSantaType(santaComponents.santaTypesDefinitions.VectorImage.svgType, state, props);
        return svgType === SVG_TYPES.UGC;
    });

    const flipTransformConsts = {
        x: {transform: 'scale(1, -1)'},
        vertical: {transform: 'scale(1, -1)'},
        y: {transform: 'scale(-1, 1)'},
        horizontal: {transform: 'scale(-1, 1)'},
        xy: {transform: 'scale(-1, -1)'},
        both: {transform: 'scale(-1, -1)'}
    };

    const flipTransformStyle = propsSelectorsUtils.createComponentSantaTypeFetcher((state, props) => {
        const compProp = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compProp, state, props) || {};
        return flipTransformConsts[compProp.flip] || {};
    });

    return {
        svgId: getSvgId,
        link: getSvgLink,
        strokeWidth: getStrokeWidth,
        svgString: getSvgString,
        svgStringFromCropData: getSvgStringFromCropData,
        svgInfo: getSvgInfo,
        legacySvgString: getLegacySvgString,
        legacySvgInfo: getLegacySvgInfo,
        svgType: getSvgType,
        overrideColorsAsCss: getOverrideColorsAsCss,
        shapeStyle: getShapeStyle,
        preserveViewBox: getPreserveViewBox,
        shouldScaleStroke: getShouldScaleStroke,
        svgPropsForMemberLoginIconItems,
        svgPropsMapForMediaControls,
        flipTransformStyle,
        getSvgStringForMask,
        setViewBox,
        setPreserveAspectRatio
    };
});

define([
    'lodash',
    'prop-types',
    'utils',
    'santa-components',
    'coreUtils',
    'santa-core-utils',
    'santaProps/utils/propsSelectorsUtils',
    'santaProps/propsBuilder/propsBuilderUtil',
    'santaProps/types/modules/VideoQoS',
    'santaProps/fetchers/vectorImageFetchers'
], function (
    _,
    PropTypes,
    utils,
    santaComponents,
    coreUtils,
    coreUtilsLib,
    propsSelectorsUtils,
    propsBuilderUtil,
    VideoQoS,
    vectorImageFetchers
) {
    'use strict';

    const createComponentSantaType = propsSelectorsUtils.createComponentSantaType;
    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;
    const consts = coreUtils.mediaConsts;
    const siteDataUtils = coreUtils.siteDataUtils;


    const playingVideoStates = [consts.playbackTypes.SEEK_PLAYING, consts.playbackTypes.PLAYING];


    function getData(state, props) {
        //todo: remove compData support (ADI still using deprecated stripContainer with compData)
        return state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compDesign, state, props) || state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
    }

    function getPageBackground(state) {
        const currentUrlPageId = state.siteData.getCurrentUrlPageId();
        const pageData = state.siteData.getDataByQuery(currentUrlPageId);
        const device = state.siteData.isMobileView() ? 'mobile' : 'desktop';
        const background = _.get(pageData, ['pageBackgrounds', device, 'ref']) || {};

        const bgOverride = _.get(state.siteData.getRootNavigationInfo(), 'pageBackgroundColorOverride');
        if (bgOverride) {
            background.color = bgOverride;
            background.mediaRef = null;
        }

        return {
            background,
            mediaSizing: _.get(pageData, ['pageBackgrounds', device, 'mediaSizing'], '')
        };
    }

    function getPopupBackground(state, props) {
        const data = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        const device = state.siteData.isMobileView() ? 'mobile' : 'desktop';
        return {background: _.get(data, ['pageBackgrounds', device, 'ref'])};
    }

    function getRenderParts(data, state, props) {
        return {
            media: {
                video: VideoQoS.getVideoRenderParts(data, state, props),
                image: []
            }
        };
    }

    const globalImageQuality = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteData.getGlobalImageQuality();
    }, 'globalImageQuality');

    const playbackUrl = createComponentSantaType(PropTypes.string, function (state, props) {
        const data = getData(state, props);
        return VideoQoS.getUrl(data, state, props);
    });

    const playbackFormat = createComponentSantaType(PropTypes.string, function (state, props) {
        const data = getData(state, props);
        return VideoQoS.getPlaybackFormat(data, state, props);
    });

    const playbackConfig = createComponentSantaType(PropTypes.object, function (state, props) {
        const data = getData(state, props);
        return VideoQoS.getPlaybackConfig(data, state, props);
    });

    const renderParts = createComponentSantaType(PropTypes.object, function (state, props) {
        const data = getData(state, props);
        return getRenderParts(data, state, props);
    });

    /**
     * POPUP
     */

    const popupBackgroundData = createComponentSantaType(PropTypes.object, getPopupBackground);

    const popupRenderParts = createComponentSantaType(PropTypes.object, function (state, props) {
        const data = getPopupBackground(state, props);
        return getRenderParts(data, state, props);
    });

    const popupPlaybackUrl = createComponentSantaType(PropTypes.string, function (state, props) {
        const data = getPopupBackground(state, props);
        return VideoQoS.getUrl(data, state, props);
    });

    const popupPlaybackFormat = createComponentSantaType(PropTypes.string, function (state, props) {
        const data = getPopupBackground(state, props);
        return VideoQoS.getPlaybackFormat(data, state, props);
    });

    const popupPlaybackConfig = createComponentSantaType(PropTypes.object, function (state, props) {
        const data = getPopupBackground(state, props);
        return VideoQoS.getPlaybackConfig(data, state, props);
    });

    /**
     * SITE BACKGROUND
     */

    const siteBackgroundData = createGlobalSantaType(PropTypes.object, getPageBackground, 'siteBackgroundData');

    const siteBackgroundRenderParts = createComponentSantaType(PropTypes.object, function (state, props) {
        const data = getPageBackground(state);
        return getRenderParts(data, state, props);
    });

    const siteBackgroundPlaybackUrl = createComponentSantaType(PropTypes.string, function (state, props) {
        const data = getPageBackground(state);
        return VideoQoS.getUrl(data, state, props);
    });

    const siteBackgroundPlaybackFormat = createComponentSantaType(PropTypes.string, function (state, props) {
        const data = getPageBackground(state);
        return VideoQoS.getPlaybackFormat(data, state, props);
    });

    const siteBackgroundPlaybackConfig = createComponentSantaType(PropTypes.object, function (state, props) {
        const data = getPageBackground(state);
        return VideoQoS.getPlaybackConfig(data, state, props);
    });

    const canVideoPlayInline = createComponentSantaType(PropTypes.bool, function (state, props) {
        const isMobileView = state.fetchSantaType(santaComponents.santaTypesDefinitions.isMobileView, state, props);
        const isMobileDevice = state.fetchSantaType(santaComponents.santaTypesDefinitions.Device.isMobileDevice, state, props);
        if (!isMobileView && !isMobileDevice) {
            return true;
        }
        const {featureDetections} = state.siteAPI.getAspectGlobalData('mediaAspect');
        const playsInline = _.get(featureDetections, 'playsInline', false);
        const compProp = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compProp, state, props);
        const enableBackgroundVideo = _.get(compProp, 'enableBackgroundVideo', true);
        return playsInline && enableBackgroundVideo;
    });

    /**
     * PLAYBACK STATES
     */

    /**
     * Get playback state from control components
     * @param state
     * @param props
     * @returns {{}}
     */
    function getStateByPlayerIdCompProp(state, props) {
        const compProp = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compProp, state, props);
        const playerState = state.siteAPI.getAspectComponentData('mediaAspect', compProp.playerId);
        return playerState || {};
    }

    /**
     * Get playback state from player components
     * @param state
     * @param props
     * @returns {{}}
     */
    function getStateByCompId(state, props) {
        const compId = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.id, state, props);
        const playerState = state.siteAPI.getAspectComponentData('mediaAspect', compId);
        return playerState || {};
    }

    /**
     * In use only in MediaPlayerPreviewExtension
     */
    const playerPlaybackState = createComponentSantaType(PropTypes.string, function (state, props) {
        const playerState = getStateByCompId(state, props);
        return normalizePlaying(playerState.playbackState);
    });

    function normalizePlaying(playbackState) {
        if (_.includes(playingVideoStates, playbackState)) {
            return consts.playbackTypes.PLAYING;
        }
        return playbackState;
    }

    const playbackState = createComponentSantaType(PropTypes.string, function (state, props) {
        const playerState = getStateByPlayerIdCompProp(state, props);
        return normalizePlaying(playerState.playbackState);
    });

    const getIsPlaybackPlayIntent = createComponentSantaType(PropTypes.func, function (state, props) {
        const playerState = getStateByPlayerIdCompProp(state, props);
        return () => playerState.previousPlaybackState === consts.playbackTypes.PLAYING;
    });

    const fullscreen = createComponentSantaType(PropTypes.bool, function (state, props) {
        const playerState = getStateByPlayerIdCompProp(state, props);
        return playerState.fullscreen;
    });

    const volume = createComponentSantaType(PropTypes.number, function (state, props) {
        const playerState = getStateByPlayerIdCompProp(state, props);
        return playerState.volume;
    });

    const muted = createComponentSantaType(PropTypes.bool, function (state, props) {
        const playerState = getStateByPlayerIdCompProp(state, props);
        return playerState.muted;
    });

    /**
     * since controls are not aware to the video data , they hold only the player component id
     * this method gets the relevant WixVideo data for the controls display ,
     */
    const controlsData = createComponentSantaType(PropTypes.object, function (state, props) {
        const compProp = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compProp, state, props);

        // get player design data and properties from playerId
        const pointers = state.siteAPI.getPointers();
        const displayDal = state.siteAPI.getDisplayedDAL();
        const pagePointer = pointers.components.getPage(props.rootId, state.siteData.getViewMode());
        const playerPointer = pointers.components.getComponent(compProp.playerId, pagePointer);
        const propertyQuery = displayDal.get(pointers.getInnerPointer(playerPointer, ['propertyQuery']));
        const designQuery = displayDal.get(pointers.getInnerPointer(playerPointer, ['designQuery']));

        const playerCompProp = propsBuilderUtil.getCompProp(state.siteAPI, propertyQuery, props.rootId);
        const playerDesignData = propsBuilderUtil.getCompDesign(state.siteAPI, designQuery, props.rootId);

        const isMobileView = state.fetchSantaType(santaComponents.santaTypesDefinitions.isMobileView, state, props);
        const {disableAudio, playerInteraction} = playerCompProp;
        return {
            allowReplay: _.get(playerInteraction, 'allowReplay', true),
            canPause: _.get(playerInteraction, 'click') === 'toggle',
            playerInHoverInteraction: _.get(playerInteraction, 'rollIn') !== 'none' && !isMobileView,
            disableAudio,
            hasAudio: _.get(playerDesignData, ['background', 'mediaRef', 'hasAudio'], true)
        };
    }, true);

    //TODO: merge with canVideoPlayInline santa type when the 'sv_videobox_mobile' experiment is merged
    const enableBackgroundVideo = createComponentSantaType(PropTypes.bool, function (state, props) {
        const isMobileView = state.fetchSantaType(santaComponents.santaTypesDefinitions.isMobileView, state, props);
        const isDesktopDevice = !state.fetchSantaType(santaComponents.santaTypesDefinitions.Device.isTouchDevice, state, props);
        const isTabletDevice = state.siteData.isTabletDevice();

        if ((isDesktopDevice || isTabletDevice) && !isMobileView) {
            return true;
        }

        const {featureDetections} = state.siteAPI.getAspectGlobalData('mediaAspect');
        const playsInline = _.get(featureDetections, 'playsInline', false);

        const compProp = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compProp, state, props);
        const enableBackgroundVideoProp = _.get(compProp, 'enableBackgroundVideo', false);

        return playsInline && enableBackgroundVideoProp;
    });

    function getMaskData(state, props) {
        return _.get(state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compProp, state, props), 'overrideMask',
            _.get(state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compDesign, state, props), ['background', 'mediaRef', 'mask'])
        );
    }

    const enableMaskedVideo = createComponentSantaType(PropTypes.bool, function (state, props) {
        const maskData = getMaskData(state, props);

        if (!maskData || !maskData.svgId) {
            return true;
        }

        const canQueryCSSSupport = state.fetchSantaType(santaComponents.santaTypesDefinitions.BrowserFlags.cssFeatureQueriesSupported, state);

        const {featureDetections} = state.siteAPI.getAspectGlobalData('mediaAspect');

        return _.get(featureDetections, 'hasCSSMask', canQueryCSSSupport);
    });

    const maskPosterFallback = propsSelectorsUtils.createComponentSantaType(PropTypes.object, (state, props) => {
        const maskData = getMaskData(state, props);

        if (!maskData || !maskData.svgId) {
            return null;
        }

        const {svgId, flip} = maskData;

        const svgString = siteDataUtils.getSvgString(state.siteData, svgId) || '';
        const svgInfo = siteDataUtils.getSvgInfo(state.siteData, svgId) || '';

        return {
            version: 2,
            svgString: vectorImageFetchers.setPreserveAspectRatio(vectorImageFetchers.setViewBox(svgString, svgInfo, true), 'none'),
            flip
        };
    });

    const mask = propsSelectorsUtils.createComponentSantaType(PropTypes.object, (state, props) => {
        const maskData = getMaskData(state, props);

        if (!maskData || !maskData.svgId) {
            return null;
        }

        const {x, y, width, height, svgId, flip, mode} = maskData;

        const svgString = vectorImageFetchers.getSvgStringForMask(state, props, svgId, flip, mode);
        return coreUtilsLib.svgMask.getCSSMask(svgString, {
            x: `${x}%`,
            y: `${y}%`,
            width: `${width}%`,
            height: `${height}%`
        });
    });

    const dropShadow = propsSelectorsUtils.createComponentSantaType(PropTypes.object, (state, props) => {
        const maskData = getMaskData(state, props);

        if (!maskData || !maskData.svgId) {
            return {};
        }

        const boxShadow = _.get(state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compDesign, state, props), ['cssStyle', 'cssBoxShadow', 0]);

        if (!boxShadow) {
            return {};
        }

        const useCssFilter = state.fetchSantaType(santaComponents.santaTypesDefinitions.BrowserFlags.useDropShadowFilterOnVideo, state);
        const {offsetX, offsetY, blurRadius, color} = boxShadow;
        const colorMap = state.siteData.getColorsMap();
        const rgba = utils.colorParser.getColor(colorMap, color);

        if (useCssFilter) {
            const cssValue = `drop-shadow(${offsetX.value}${offsetX.unit} ${offsetY.value}${offsetY.unit} ${blurRadius.value}${blurRadius.unit} ${rgba})`;

            return {
                css: {
                    filter: cssValue,
                    WebkitFilter: cssValue
                }
            };
        }

        const {svgId, flip} = maskData;
        const shadow = {
            x: offsetX.value,
            y: offsetY.value,
            blurRadius: blurRadius.value,
            color: utils.colorParser.getHexColor(colorMap, color),
            opacity: color.alpha
        };
        const layout = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.layout, state, props);

        const svgString = vectorImageFetchers.getSvgStringForMask(state, props, svgId, flip, 'drop-shadow', shadow, layout);

        return {
            svg: svgString
        };
    });

    const flipTransformConsts = {
        vertical: {transform: 'scale(1, -1)'},
        horizontal: {transform: 'scale(-1, 1)'},
        both: {transform: 'scale(-1, -1)'}
    };

    //videobox flip css style fetcher
    const flipTransformStyle = createComponentSantaType(PropTypes.object, (state, props) => {
        const compProp = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compProp, state, props) || {};
        return flipTransformConsts[compProp.flip] || {};
    });

    return {
        globalImageQuality,

        renderParts,
        playbackUrl,
        playbackFormat,
        playbackConfig,
        canVideoPlayInline,
        playerPlaybackState,
        playbackState,
        getIsPlaybackPlayIntent,
        fullscreen,
        volume,
        muted,
        controlsData,
        enableBackgroundVideo,
        flipTransformStyle,

        SiteBackground: {
            data: siteBackgroundData,
            renderParts: siteBackgroundRenderParts,
            playbackUrl: siteBackgroundPlaybackUrl,
            playbackFormat: siteBackgroundPlaybackFormat,
            playbackConfig: siteBackgroundPlaybackConfig
        },
        Popup: {
            data: popupBackgroundData,
            renderParts: popupRenderParts,
            playbackUrl: popupPlaybackUrl,
            playbackFormat: popupPlaybackFormat,
            playbackConfig: popupPlaybackConfig
        },
        enableMaskedVideo,
        mask,
        dropShadow,
        maskPosterFallback
    };
});

